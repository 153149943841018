<!-- 
  this one is using data that we got from API project/project_site_milestone.show_by_site/<id_site>

  so for this test ui, we populate data by execute method get_project_site_milestone()
  then to construct the grid, execute method createColumnDefs();

  note: check <ag-grid-vue> in <template>, there is :detailCellRendererParams="detailCellRendererParams"
  that we define in data, we define the sub grid there, and to populate it, using getDetailRowData that getting the data from sub array (which is: milestone_tasks) from the api above.

  and one other thing that important is :masterDetail="true"

  populating the detail/sub grid, using initiateSubGrid()
-->

<template>
  <div class="bg-default-dashboard">
    <div class="py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%">
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Site List</h1>
          </b-col>
        </b-row>
        <b-row>
          <div class="d-flex flex-column h-100 w-100 p-2">
            <div class="d-flex flex-column h-100 w-100 flex-grow-1 flex-shrink-1">
              <div style="padding: 4px">
                <div style="float: right" class="d-flex">
                  <input
                    class="mr-2 form-control d-inline-block"
                    @keyup="onQuickFilterChanged"
                    type="text"
                    id="quickFilterInput"
                    placeholder="Type text to filter..."
                  />
                  <!--
                  <button
                    class="btn btn-primary mr-2"
                    :disabled="!showGrid"
                    @click="showGrid = false"
                    style="white-space: nowrap"
                  >
                    Destroy Grid
                  </button>
                  <button
                    class="btn btn-primary"
                    :disabled="showGrid"
                    @click="showGrid = true"
                    style="white-space: nowrap"
                  >
                    Create Grid
                  </button>-->
                </div>
              </div>
              <div style="clear: both"></div>
              <div
                v-if="showGrid"
                class="d-flex flex-column flex-grow-1 flex-shrink-1"
              >
                <div style="padding: 4px" class="btn-toolbar">
                  <span>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.selectAll()"
                    >
                      Select All
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.deselectAll()"
                    >
                      Clear Selection
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      v-on:click="toggleSideBar()"
                    >
                      Show Side Bar
                    </button>
                    <b-button size="md" variant="success" @click="openPlannerProject">Project List</b-button>
                    <b-button size="md" variant="success" @click="openPlannerLandingPage">Main Menu</b-button>
                  </span>
                </div>
                <ag-grid-vue
                  style="width: 100%; height: 100%"
                  class="flex-grow-1 flex-shrink-1 ag-theme-alpine"
                  :columnDefs="columnDefs"
                  :rowData="rowData"
                  rowSelection="multiple"
                  :sideBar="sideBar"
                  :statusBar="statusBar"
                  :modules="modules"
                  :defaultColDef="{
                    sortable: true,
                    resizable: true,
                    filter: true,
                    floatingFilter: true,
                  }"
                  @grid-ready="onReady"
                  :masterDetail="true"
                  :detailCellRendererParams="detailCellRendererParams"
                  @cell-value-changed="onCellValueChanged"
                  :getContextMenuItems="getContextMenuItems"
                >
                  <!-- 
                  :groupHeaders="true"
                  :suppressRowClickSelection="true"
                  :allowContextMenuWithControlKey="true"
                  rowSelection="multiple"

                  @cell-clicked="onCellClicked"
                  @cell-double-clicked="onCellDoubleClicked"
                  @cell-context-menu="onCellContextMenu"
                  @cell-value-changed="onCellValueChanged"
                  @cell-focused="onCellFocused"
                  @row-selected="onRowSelected"
                  @selection-changed="onSelectionChanged"
                  @filter-modified="onFilterModified"
                  @virtual-row-removed="onVirtualRowRemoved"
                  @row-clicked="onRowClicked"
                  @column-everything-changed="onColumnEvent"
                  @column-row-group-changed="onColumnEvent"
                  @column-value-Changed="onColumnEvent"
                  @column-moved="onColumnEvent"
                  @column-visible="onColumnEvent"
                  @column-group-Opened="onColumnEvent"
                  @column-resized="onColumnEvent"
                  @column-pinned-count-changed="onColumnEvent"                
                
                -->
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import { AgGridVue } from "@ag-grid-community/vue";
import HeaderGroupComponent from "./HeaderGroupComponent.vue";
import axios from "@/util/axios";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { apiDomain, getHeader } from "@/assets/js/config.js";
import "@ag-grid-enterprise/core";
import moment from "moment";

// import DisplaySiteDetailRenderer from "./cell-renderers/displaySiteDetailRenderer.vue";
// import CustomButtonComponent from './cell-renderers/customButtonComponentVue.js';

/*
https://www.ag-grid.com/javascript-data-grid/group-cell-renderer/#group-cell-renderer-configuration
https://www.ag-grid.com/javascript-data-grid/master-detail/
  https://www.ag-grid.com/examples/master-detail/simple/packages/vanilla/

https://plnkr.co/edit/?open=main.ts
  https://run.plnkr.co/preview/cm11wdz7q000a35712ix4cfes/

https://www.ag-grid.com/vue-data-grid/master-detail-grids/
  https://www.ag-grid.com/example-assets/master-detail-data.json
  https://www.ag-grid.com/examples/master-detail-grids/grid-options/packages/vue3/

https://www.ag-grid.com/vue-data-grid/master-detail-nesting/
https://www.ag-grid.com/vue-data-grid/server-side-model-master-detail/

https://www.ag-grid.com/javascript-data-grid/master-detail-nesting/
https://community.plotly.com/t/ag-grid-master-detail-how-to-access-detail-grid-data-in-a-callbak/81695/6

*/

export default {
  mixins: [ErrorFunctionMixin],
  data() {
    return {
      model:{
        site_id : null,
        status_id:null,
        site_name:null,
        site_latitude:null,
        site_longitude:null,
        site_code:null,
        site_postal_code:null,
        site_address:null,
        site_cod_date:null,
        site_target_turn_on_date:null,
        site_actual_turn_on_date:null,
        site_as_built_capacity:null,
        site_module_brand_id:null,
        site_module_capacity:null,
        site_module_quantity:null,
        site_module_allocation_total_capacity:null,
        site_drawing_module_brand_id:null,
        site_drawing_module_capacity:null,
        site_drawing_module_quantity:null,
        site_drawing_total_capacity:null,
      },
      obj_project: {
        id_project: null,
        tx_project: null,
        id_site: null,
        tx_site: null,
        id_milestone: null,
        id_milestone_task: null,
      },
      obj_user: {
        info: null,
        is_pm_leader: false,
        pm_group_id: null,
        pm_members: [],
      },
      obj_hit_api: {
        id_site: null,
        id_task: null,
        // selected_row_data: null,   //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        // updated_field: null,       //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        to_be_update_data: {},
      },
      obj_selection: {
        //_ we can change this later, if there is an API for get parameterize setting value
        ms_critical: ['Yes', 'No'],
        ms_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
      },
      detailCellRendererParams: null,
      //_ NOTE: above from this line is new

      showFilters: false,
      api: null,
      columnDefs: null,
      showGrid: false,
      sideBar: false,
      rowCount: null,
      modules: [
        ClientSideRowModelModule,
        ColumnsToolPanelModule,
        ExcelExportModule,
        FiltersToolPanelModule,
        SparklinesModule,
        GridChartsModule,
        MasterDetailModule,
        MenuModule,
        MultiFilterModule,
        RangeSelectionModule,
        RichSelectModule,
        RowGroupingModule,
        ServerSideRowModelModule,
        SetFilterModule,
        SideBarModule,
        StatusBarModule,
        ViewportRowModelModule,
        ClipboardModule,
      ],
      rowData: [],
      site_status:[],
      site_status_index:[],
      module_brands :[],
      module_brands_index:[],
      filter: {
        developers: [],
        project_managers: [],
        project_type: [],
        project_name:null,
      },
      status_bar:
          {
            statusPanels: [
              { statusPanel: "agTotalAndFilteredRowCountComponent" },
              {statusPanel: "agTotalRowCountComponent" },
              {statusPanel: "agFilteredRowCountComponent" },
              {statusPanel: "agSelectedRowCountComponent" },
              {statusPanel: "agAggregationComponent" },
            ],
          },
      site_status : [],
      site_status_index:[],
      site_milestones: [],
    };
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));

    this.myUserID = LoginUser;
    var project_site_access = JSON.parse(window.localStorage.getItem("project_site_access"));
    //_ init dummy who is login
    this.obj_project.id_site = '7384';
    //this.get_site_detail_info();

    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );

    // console.log(LoginUser);
    // console.log(project_site_access);
  },
  components: {
    AgGridVue,
    // HeaderGroupComponent,
  },
  setup() {},
  methods: {
    openPlannerLandingPage()
        {
            this.$router.push('plannerLandingPage');
        },
    openPlannerProject()
        {
            this.$router.push('plannerproject');
        },

  //01.01 BUILD THE GRID - CONFIGURE THE COLUMN
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: '#', minWidth: 60, width: 60, checkboxSelection: true, sortable: false,
          pinned: true, suppressHeaderMenuButton: true,
        },
        {
          headerName: 'Project Details',
          headerGroupComponent: 'HeaderGroupComponent',
          children: [
              {
                headerName: "Project Name",
                field: "project.project_name",
                flex: 1,
                // width: 250,
                cellRenderer: "agGroupCellRenderer",
                pinned: true,
                floatingFilter: true,
                cellRenderer: "agGroupCellRenderer",
              },
              { 
                headerName: "Project Manager", 
                field: "project.project_manager.name", 
                width: 150, 
                floatingFilter: true, 
                pinned: true, 
                suppressHeaderMenuButton: true, 
            },
          ]
        },
        {
          headerName: 'Site Profile',
          children: [
            {
              headerName: "Project Site",
              field: "site_name",
              width: 300,
              editable: true,
              // floatingFilter: true,
              // suppressHeaderMenuButton: true,
            },
            { 
              headerName: "Site Status", 
              field: "status.status_code", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.site_status }, 
              filter: 'agSetColumnFilter', 
              editable: true, 
            },
            { headerName: "Address", field: "site_address", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true,  hide:true,},
            { headerName: "Postal Code", field: "site_postal_code", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true, hide:true, },
            { headerName: "Latitude", field: "site_latitude", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { headerName: "Longtitude", field: "site_longitude", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { headerName: "EPC", field: "EPC", width: 150, suppressHeaderMenuButton:true,},
            { headerName: "Site Code", field: "site_code", width: 150, suppressHeaderMenuButton:true,editable:true,},
            { headerName: "Roof Handover Date", field: "", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { headerName: "COD", field: "site_cod_date", width: 150, suppressHeaderMenuButton: true,editable:true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },
            { headerName: "Planned Turn On Date", field: "site_target_turn_on_date", width: 150,editable:true,suppressHeaderMenuButton: true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },
            { headerName: "Actual Turn On Date", field: "site_actual_turn_on_date", width: 150,editable:true,suppressHeaderMenuButton: true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },      
            { headerName: "Site PPA Total Capacity(kWp)", field: "", width: 150, suppressHeaderMenuButton: true,hide:true,},
            { headerName: "As Built Capacity(kWp)", field: "site_as_built_capacity", width: 150, suppressHeaderMenuButton: true,editable:true},

          ],
        },
        {
          headerName: 'IFA/IFC/Module Allocation',
          children: [
            {
              headerName: 'Module Allocation',
                children: [
                { 
                  headerName: "Brand", 
                  field: "site_module_brand.module_brand_id_name", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.module_brands }, 
                  filter: 'agSetColumnFilter', 
                  editable: true, 
                  hide:true,
                },
                { 
                  headerName: "Number of Module", 
                  field: "site_module_quantity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  editable:true,
                  hide:true,
                },
                {
                  headerName: "Watt Class", 
                  field: "site_module_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  hide:true,
                },
                {
                  headerName: "Total Capacity(kWp)", 
                  field: "site_module_allocation_total_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  hide:true,
                },

                ]
            },
          {
            headerName: 'Drawing',
                children: [
                { 
                  headerName: "Brand", 
                  field: "site_drawing_module_brand.module_brand_id_name", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.module_brands }, 
                  filter: 'agSetColumnFilter', 
                  editable: true, 
                  hide:true,
                },
                { 
                  headerName: "Number of Module", 
                  field: "site_drawing_module_quantity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  editable:true,
                  hide:true,
                },
                {
                  headerName: "Watt Class", 
                  field: "site_drawing_module_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  hide:true,
                },
                {
                  headerName: "Total Capacity(kWp)", 
                  field: "site_drawing_total_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  hide:true,
                },

                ]
          },
          { headerName: "Remarks", field: "site_ifc_ifa_module_remarks", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable:true, hide:true,},
          ]
        },

      ];
    },
    initiateSubGrid() {
      this.detailCellRendererParams = {
        detailGridOptions: {
          columnDefs: [
            { headerName: "Task Name", field: "site_milestone_code", flex: 1.5 },
            // { headerName: "Planned Start Date", field: "planned_start_date", width: 200, 
            //    cellRenderer: (params) => { return this.format_datetime(params.data.planned_start_date) } 
            // },
            // { headerName: "Planned End Date", field: "planned_end_date", width: 200,
            //   editable: true, headerClass: 'agisEditable', 
            //   cellRenderer: (params) => { return this.format_datetime(params.data.planned_end_date) } 
            // },
            { headerName: "Planned Start Date", field: "planned_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_start_date) },
            },
            { headerName: "Planned End Date", field: "planned_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_end_date) },
            },
            { headerName: "Actual Start Date", field: "actual_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_start_date) },
            },
            { headerName: "Actual End Date", field: "actual_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_end_date) },
            },
            { headerName: "Remarks", headerClass: 'agisEditable',field: "remarks", width: 200,  editable: true },
          ],
          headerHeight: 38,
          // onCellValueChanged: function ($event) {    <-- NOTE: geesshhh, spent more than half day figuring it how to access vue method dari onCellValueChanged, baru ingat kalau pakai arrow function, "this"-nya ga ke bind ama object onCellValueChanged, makanya dari tadi pakai function, "this"-nya ngerefer ke onCellValuChanged object, and ga ada class/method populate_data_to_be_submit_into_db di dalam onCellValueChanged
          onCellValueChanged: ($event) => {
            this.populate_data_to_be_submit_into_db($event);
            //this.api_action_milestone_task('update');
          }
        },

        getDetailRowData: (params) => {
          // supply details records to detail cell renderer (i.e. detail grid)
          params.successCallback(params.data.milestone_tasks);
        },
        // tmpAction: this.populate_data_to_be_submit_into_db.bind(this),
      };
    },
  //01.01 END


  //02.01 UPDATING FIELD AND SAVE TO DB

    onCellValueChanged(event) {
      //console.log(event);
      console.log('onCellValueChanged: '+ event.colDef.field + '=' + event.oldValue + ' to ' + event.newValue);
      this.onCellValueChangedSite(event);
    },

    onCellValueChangedSite(event) {
      this.model.site_id = event.data.site_id;
      this.model.status_id = this.searchKeyword(event.data.status.status_code,this.site_status_index);
      this.model.site_name = event.data.site_name;
      this.model.site_latitude = event.data.site_latitude;
      this.model.site_longitude = event.data.site_longitude;
      this.model.site_code = event.data.site_code;
      this.model.site_address = event.data.site_address;
      this.model.site_postal_code = event.data.site_postal_code;
      this.model.site_cod_date = event.data.site_cod_date;
      this.model.site_actual_turn_on_date = event.data.site_actual_turn_on_date;
      this.model.site_target_turn_on_date = event.data.site_target_turn_on_date;
      this.model.site_as_built_capacity = event.data.site_as_built_capacity;
      this.model.site_module_brand_id = this.searchKeyword(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_capacity = this.searchKeywordOther(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_quantity = event.data.site_module_quantity;
      this.model.site_module_allocation_total_capacity = (this.model.site_module_capacity * this.model.site_module_quantity)/1000
      this.model.site_drawing_module_brand_id = this.searchKeyword(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_capacity = this.searchKeywordOther(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_quantity = event.data.site_drawing_module_quantity;
      this.model.site_drawing_total_capacity = (this.model.site_drawing_module_capacity * this.model.site_drawing_module_quantity)/1000

      axios.post(apiDomain + 'project/update_project_site_details/' + event.data.site_id, this.model, { headers: getHeader() })
      //axios.put(apiDomain + 'project/project_site/' + event.data.site_id, this.model, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });

    },


    populate_data_to_be_submit_into_db( AgEvent ) {
      // console.log('populate_data_to_be_submit_into_db');
      // console.log(AgEvent);
      // console.log(this.newrowdata);


  

      const site_data = this.newrowData.find(obj => obj.site_id === AgEvent.data.site_id);

      // console.log('Find the row data index');
      // console.log(site_data.milestone_tasks);


      this.site_milestones = site_data.milestone_tasks;

      // console.log('Transfer to this.site_milestones');
      // console.log(this.site_milestones);
      
      // console.log('Check the changes is in whichrow');
      // console.log(AgEvent.rowIndex);

      const updated_index = AgEvent.rowIndex;

      // console.log('show the new set of data');
      // console.log(AgEvent.data);

      

      this.site_milestones[updated_index] = AgEvent.data;

      // console.log ("New Milestone");
      // console.log (this.site_milestones);

      axios.post(apiDomain + 'project/project_site_milestone.update_site_milestones/' + AgEvent.data.site_id, this.site_milestones, { headers: getHeader() })
      .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site Milestone: ' + site_data.site_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });






      //_ since it's repetition, we use this function to simplify the detail grid instantiation
      //  why ? the API required some fields, such as task name | plan start | plan end | taskstatus

      //  NOTE: task name | plan start | plan end is not suppose to be change, right ?
      //  if those fields allowed to be change, then the logic down here need to be change

      // this.obj_hit_api.id_site = this.obj_project.id_site;
      // this.obj_hit_api.id_task = AgEvent.data.site_milestone_task_id;

      // //_ initiate un-change required field
      // let obj_default = {
      //   site_milestone_task_name: AgEvent.data.site_milestone_task_name,
      //   site_milestone_task_planned_start_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_start_date, 'db'),
      //   site_milestone_task_planned_end_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_end_date, 'db'),
      // };

      // //_ is status the one that being update or not ? because it's one of required field need to be post to API
      // //  if it not status, then we use the status as is from the db (or what we got from the API)
      // let updated_field = AgEvent.colDef.field;
      // let updated_value = '';
      // if ( updated_field == 'site_milestone_task_actual_start_date' || updated_field == 'site_milestone_task_actual_end_date' ) {
      //   updated_value = this.format_datetime(AgEvent.newValue, 'db');
      // } else {
      //   updated_value = AgEvent.newValue
      // }
      // if ( updated_field != 'site_milestone_task_status' ) {
      //   obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status, [updated_field]: updated_value }
      // } else {
      //   obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status }
      // }

      // this.obj_hit_api.to_be_update_data = { ...obj_default }

      // console.log(this.obj_hit_api);
    },


    onCellValueChangedSiteMilestone() {
      axios.post(apiDomain + 'project/project_site_milestone.update_site_milestones/' + this.model.site_id, this.site_milestones, { headers: getHeader() })
      .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });

    },

    getProjectSiteStatusListing() 
    {
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.site_status.push(response.data.data[i].status_code);
                              this.site_status_index.push({id:response.data.data[i].status_id,value:response.data.data[i].status_code});                                
                            }
                            // console.log("SITE STATUS");
                            // console.log(this.site_status);
                            // console.log(this.site_status_index);
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
    },


    api_action_milestone_task ( whatAction='none' ) {
      console.log('api_action_milestone_task');
      console.log(this.obj_hit_api);
      
      if (whatAction == 'get') {
        //_ 1.	Get Single Project Site Milestone API: http://{domain_url} /task/get_site_milestone_task_by_id /{project_site_id}/{site_milestone_task_id}
        console.log("api_action_milestone_task: GET");

        // console.log( this.obj_project.id_site );
        // console.log(this.obj_hit_api);

        axios
          .get(apiDomain + "task/get_site_milestone_task_by_id/" + this.obj_hit_api.id_site + "/" + this.obj_hit_api.id_task, { headers: getHeader() })
          .then((response) => {
            if (response.status === 200) {
              //console.log(response);
            }
          })
          .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Get Single Project Site Milestone");
          })
          .finally(() => {
            //_
          });  
      } else if (whatAction == 'update') {
        //_ 3.	Update Project Site Milestone Task API: http://{domain_url} /task/edit_site_milestone_task/{project_site_id}/{site_milestone_task_id}
        //console.log("api_action_milestone_task: UPDATE");

        axios
          .post(apiDomain + 'task/edit_site_milestone_task/' + this.obj_hit_api.id_site + "/" + this.obj_hit_api.id_task, this.obj_hit_api.to_be_update_data, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Milestone Task: Successfully Updated.</b>',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            }
          })
          .catch(error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Project Information")
          });
      } else if (whatAction == 'delete') {
        //console.log("api_action_milestone_task: DELETE");

      } else {
        this.redErrorNotify("<b>Selected action not valid! </b>");       
      }
    },
    get_project_site_milestone(site_id = 0) {
      //https://momensfile.sembcorp.com/project/project_site_milestone.show_by_site/7384
      //console.log("get_project_site_milestone");

      axios
        .get(
          apiDomain + "project/project_site_milestone.show_by_site/" + site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.rowData = response.data.data;
            //console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
        });
    },
    get_site_detail_info() {
      //console.log('get_site_detail_info');
      //_ this one, nothing to do with milestone, just showing info of project / site 
      //  <h1 class="text-white">Project Milestone & Task ( {{ obj_project.tx_site }} )</h1>

      axios
        .get(apiDomain + "project/project_site/" + this.obj_project.id_site, {headers: getHeader(),})
        .then((response) => {
            if (response.status === 200) {
              // console.log(response.data.data);
              this.obj_project.id_project = response.data.data[0].project.project_id
              this.obj_project.tx_project = response.data.data[0].project.project_name
              this.obj_project.id_site = response.data.data[0].site_id
              this.obj_project.tx_site = response.data.data[0].site_name

              // console.log(this.obj_project);
            }
        })
        .catch((error) => {
            this.errorFunction(error, "Site Information");
        });
    },

    /* CUSTOM library, make it DRY and clean code */
    format_datetime( tmp_value, isFor='ui' ) {
      //_ default from db/api datetime format in iso8601, ex.2024-06-08T00:00:00.000000Z
      //  so isFor='ui' we format it into readable format DD Mmm YYYY
      //  the default is for frontend UI, if we didn't pass the 2nd parameter
      //  to save into db, change isFor='db', so it's formatted into YYYY-MM-DD H:i:s  <- this is the value accepted, tried YYYY-MM-DD only, and failed
      if ( isFor == 'ui' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('DD MMM YYYY');
      } else if ( isFor == 'db' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
      
    },


    

    populate_data_to_be_submit_into_db_ORIGINAL( AgEvent ) {
      //console.log('populate_data_to_be_submit_into_db');
      //console.log(AgEvent);

      //_ since it's repetition, we use this function to simplify the detail grid instantiation
      //  why ? the API required some fields, such as task name | plan start | plan end | taskstatus

      //  NOTE: task name | plan start | plan end is not suppose to be change, right ?
      //  if those fields allowed to be change, then the logic down here need to be change

      this.obj_hit_api.id_site = this.obj_project.id_site;
      this.obj_hit_api.id_task = AgEvent.data.site_milestone_task_id;

      //_ initiate un-change required field
      let obj_default = {
        site_milestone_task_name: AgEvent.data.site_milestone_task_name,
        site_milestone_task_planned_start_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_start_date, 'db'),
        site_milestone_task_planned_end_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_end_date, 'db'),
      };

      //_ is status the one that being update or not ? because it's one of required field need to be post to API
      //  if it not status, then we use the status as is from the db (or what we got from the API)
      let updated_field = AgEvent.colDef.field;
      let updated_value = '';
      if ( updated_field == 'site_milestone_task_actual_start_date' || updated_field == 'site_milestone_task_actual_end_date' ) {
        updated_value = this.format_datetime(AgEvent.newValue, 'db');
      } else {
        updated_value = AgEvent.newValue
      }
      if ( updated_field != 'site_milestone_task_status' ) {
        obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status, [updated_field]: updated_value }
      } else {
        obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status }
      }

      this.obj_hit_api.to_be_update_data = { ...obj_default }

      //console.log(this.obj_hit_api);
    },




    /**
     * Debug and testing
     * 
     */
    get_milestones_by_site_id(site_id = 0) {
      //console.log("get_milestones_by_site_id");

      axios
        .get(
          apiDomain + "task/get_site_milestone_task_list_by_site_id/" + site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            //console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
        });
    },

    get_milestone_template_detail_task(ms_task_template_id = 0) {
      //console.log("get_milestone_template_detail_task");

      axios
        .get(
          apiDomain +
            "task/get_milestone_template_detail_task/" +
            ms_task_template_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            //console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones template detail task");
        })
        .finally(() => {
          //_
        });
    },

    get_milestone_template_task(ms_template_id = 0) {
      //console.log("get_milestone_template_task");

      axios
        .get(apiDomain + "task/get_milestone_template_task/" + ms_template_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            // this.rowData = response.data.data;
            //console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones template detail task");
        })
        .finally(() => {
          //_
        });
    },


    //_ original code
    pad(num, totalStringSize) {
      let asString = num + "";
      while (asString.length < totalStringSize) asString = "0" + asString;
      return asString;
    },
    onReady(params) {
      //console.log("onReady");

      this.api = params.api;
      this.api.sizeColumnsToFit();
    },
    onQuickFilterChanged(event) {
      this.api.setQuickFilter(event.target.value);
    },



    onColumnEvent(event) {
      //console.log('onColumnEvent: ' + event);
    },
    onSelectionChanged() {
      //console.log('selectionChanged');
    },
        toggleSideBar() {
      this.sideBar = !this.sideBar;
      // this.api.setSideBar(this.sideBar);
      this.api.setSideBarVisible(this.sideBar);
    },

    /**
     *  BELOW IS NOT USE ANYMORE 
     */

    //_ somehow (still don't know why) using global variable that we set in data, cannot be instantiate in detail grid
    //  but if we make a func / method, and then we use it in the detail grid, it work, the selection populated.
    bind_selection_field_critical () {
      return ['Yes', 'No'];
    },
    bind_selection_field_status () {
      return ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'];
    },
    custom_cell_render_param () {
      //_ mimicking ag-grid onCellChangeValue

      // cellRenderer: (params) => {
      //   // console.log(params);
      //   params.eGridCell.addEventListener('change', (e) => {
      //     // this.getDetails(e.target.value);
      //     // console.log(params);
      //     // console.log(e);
      //     // console.log('old: ' + params.value);
      //     // console.log('new: ' + e.target.value);
      //     this.api_action_milestone_task();
      //   });
      //   // `${params.data.site_milestone_task_planned_start_date}`;

      //   // const retval_date = ( params.data.site_milestone_task_actual_end_date === null ) ? '' : params.data.site_milestone_task_actual_end_date;
      //   let selected_field = params.data.site_milestone_task_actual_end_date;
      //   const retval_date = ( selected_field === null ) ? '' : moment(selected_field).format('DD MMM YYYY');
      //   const eDiv = document.createElement('div');
      //   eDiv.innerHTML = `<span title="the tooltip">${retval_date}</span>`;
      //   return eDiv;
      // },
    },
    populate_data_to_be_submit_into_db_v1() {
      //console.log('populate_data_to_be_submit_into_db');
      //_ since it's repetition, we use this function to simplify the detail grid instantiation
      //  why ? the API required some fields, such as task name | plan start | plan end | taskstatus

      //  NOTE: task name | plan start | plan end is not suppose to be change, right ?
      //  if those fields allowed to be change, then the logic down here need to be change

      this.obj_hit_api.id_site = this.obj_project.id_site;
      this.obj_hit_api.id_task = this.obj_hit_api.selected_row_data.site_milestone_task_id;

      //console.log(this.obj_hit_api.updated_field);

      //_ initiate un-change required field
      let tmp_obj = {
        site_milestone_task_name: this.obj_hit_api.selected_row_data.site_milestone_task_name,
        site_milestone_task_planned_start_date: this.format_datetime(this.obj_hit_api.selected_row_data.site_milestone_task_planned_start_date, 'db'),
        site_milestone_task_planned_end_date: this.format_datetime(this.obj_hit_api.selected_row_data.site_milestone_task_planned_end_date, 'db'),
      };

      //_ since it's grid and the update ALWAYS 1 field only, so it's gonna be the 1st array, so we can make validation and check here, is status the one that being updat or not
      //  by checking the objet key, if it not status, the we use the status as is from the db (or what we got from the API)
      if ( Object.keys(this.obj_hit_api.updated_field)[0] != 'site_milestone_task_status' ) {
        tmp_obj = { ...tmp_obj, site_milestone_task_status: this.obj_hit_api.selected_row_data.site_milestone_task_status }
      } 
      
      this.obj_hit_api.to_be_update_data = { ...tmp_obj, ...this.obj_hit_api.updated_field }

      //console.log(this.obj_hit_api.to_be_update_data);
    },

    getDataToShow() {
      //_ role planner display all project and sites
      //_ role project manager leader - list all project and site for all project manager under him/her
      //_ role project manager - list all project and site only for his/her own
      // console.log('>>> func getDataToShow');
      // console.log(this.obj_user.info);

      this.filter.project_type.push(1);

      if (this.myUserID.role_id == 19 || this.myUserID.role_id == 3) 
      {
        this.filter.developers.push(1);
        //this.filter.project_name='Franklin Offshore';
        this.createRowData();
        // 19: role planner
      } else if (this.obj_user.info.role_id == 9) {
        this.obj_user.pm_members.push(this.obj_user.info.id);
        this.getPMLeader();
        //console.log(this.obj_user);
      } else {

      }
    },
    getPMLeader() {
      //_ check the user is project manager leader or not
      let arr_engineer_group = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_engineer_group = response.data.data;
          }
        })
        .catch(error => {
          this.errorFunction(error, "Project Engineer Groups Listing")
        })
        .finally(() => {
          // console.log('>>> arr_engineer_group');
          // console.log(arr_engineer_group);

          //_ simplify using filter
          let tmpID = this.obj_user.info.id;
          
          var arr_filtered = arr_engineer_group.filter(function (value, index, arr) {
            return value.engineer_group_leader.id === tmpID;
          });

          // if ( arr_engineer_group.length > 0 ) {
          if (arr_filtered.length > 0) {
            this.obj_user.is_pm_leader = true;
            this.obj_user.pm_group_id = arr_filtered[0].engineer_group_id;
          }

          if (this.obj_user.is_pm_leader) 
          {
            this.getPMMembers();
          } 
          else 
          {
            this.filter.project_managers.push(this.obj_user.info.id);
            this.createRowData();
          }
        });
    },

    getPMMembers() {
      //_ get project members
      let arr_pm_members = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + this.obj_user.pm_group_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_pm_members = response.data.data.map((item) => {
              return item.engineer_group_member.id;
            });
          }
        })
        .catch(error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Engineer Group Member")
        })
        .finally(() => {
          this.obj_user.pm_members = this.obj_user.pm_members.concat(arr_pm_members);
          this.this.createRowData();
        });
    },

    
    createRowData() {
      axios.post('project/project_site.show_by_filter',this.filter, {
        headers: getHeader(),
      })
        .then(response => {  
          this.newrowData = response.data.data;
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })

        .finally(() => {
          this.getMilestone();
        });
    },


    getMilestone()
    {
      //console.log('TOTAL RECORD');
      //console.log(this.newrowData.length);
            
      for(let i=0; i < this.newrowData.length; i++)
          { 
                  axios.get(apiDomain + 'project/project_site_milestone.show_by_site/' + this.newrowData[i].site_id, { headers: getHeader() })
                    .then ( result => {
                        if (result.status === 200)
                        {
                            //console.log(result.data.data[0].contractor.contractor_name);
                            //this.contractor = result.data.data[0].contractor.contractor_name;
                            this.newrowData[i] = {...this.newrowData[i], milestone_tasks:result.data.data};
                            this.api.updateGridOptions({rowData:this.newrowData});
                            //console.log("DATA NO:" + i);
                            //console.log(this.newrowData);       
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    })                   
          }       
    },

    getContractorFromSite(site_id)
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            //console.log(response.data.data[0].contractor.contractor_name);
                            this.contractor = response.data.data[0].contractor.contractor_name;  
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    } );
            },

    searchData() {
      axios.post('project/project_site.show_by_filter', this.model, {
        headers: getHeader(),
      })
        .then(response => {
          this.rowData = response.data.data;

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    searchKeyword(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.id : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },

      searchKeywordOther(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.other : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },

    getProjectSiteStatusListing() 
    {
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.site_status.push(response.data.data[i].status_code);
                              this.site_status_index.push({id:response.data.data[i].status_id,value:response.data.data[i].status_code});                                
                            }
                            // console.log("SITE STATUS");
                            // console.log(this.site_status);
                            // console.log(this.site_status_index);
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
    },

    getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.module_brands.push(response.data.data[i].module_brand_id_code);
                              this.module_brands_index.push({id:response.data.data[i].module_brand_id,value:response.data.data[i].module_brand_id_code,other:response.data.data[i].watt_class});                                
                            }
                            // console.log("Module Brand");
                            // console.log(this.module_brands);
                            // console.log(this.module_brands_index);
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },
            getContextMenuItems(event) {
            return [
                'copy',
                'copyWithHeaders',
                'paste',
                'separator',
                {
                    name: 'Export',
                    subMenu: [
                        {
                            name: 'CSV Export',
                            action: () => {
                                this.api.exportDataAsCsv({
                                    // skipColumnGroupHeaders:true,
                                    onlySelected: true
                                });
                            },
                            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
                        },
                        {
                            name: 'Excel Export',
                            action: () => { this.api.exportDataAsExcel({ onlySelected: true }) },
                            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
                        },
                    ],
                    icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
                },
                'separator',
                'chartRange',
                'pivotChart',
                'separator',
                'resetColumns',
                'separator',
                'toolPanel',
            ];
        },
  },
  beforeMount() {
    this.api = {};
    this.obj_user.info = this.myUserID;
    this.getProjectSiteStatusListing();
    this.getModuleBrandListing();

    this.getDataToShow();

    this.createColumnDefs();
    this.initiateSubGrid();

    this.showGrid = true;
  },
  mounted() {
  }
};
</script>

<style>
.agisEditable {
  background-color: #98bcdb;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 700px;
}

.ag-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

label {
  font-weight: normal !important;
}

.bg-default-dashboard {
  background-color: #082a2f;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ag-menu {
  z-index: 200;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
</style>
